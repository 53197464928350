import React from 'react';
import './index.scss';

const Certification = () => (
  <div className="certified-section">
    <div className="certified-title">Magento Certified Developer</div>
    <div className="certified-link-data">
      <a href="https://www.credly.com/badges/d0c6b952-dae0-4987-82cd-182f81e62a41" className="certified-link" target="_blank" rel="noreferrer">
        <img src="/assets/Adobe_Certified_Professional_Experience.png" alt="Hitesh Vaghasiya Magento 2(Adobe Commerce) Certification" className="certified-image" />
        <span className="certified-adobe-title">Adobe Certified Professional Commerce Developer</span>
      </a>
    </div>
  </div>
);

export default Certification;
