/* eslint-disable react/prop-types */
import React from 'react';
import Sidebar from '../components/Sidebar';
import SEO from '../components/SEO';
import './about.scss';

const AboutPage = ({ data }) => (
  
  <div className="aboutpageMain">
    <div className="container">
      <div className="aboutpage row col-number-1">
      <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 order-lg-1 aboutimage">
          <div className="avatardiv">
            <img className="abotavatar" src="/assets/about_us_page/about.jpg" alt="Hitesh Vaghasiya"></img>
          </div>
        </div>
        <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 order-lg-2 aboutdata">
          <div className="introw">
            <h1 className="hellotext">Hello,<br/>I Am Hitesh Vaghasiya</h1>
            <h2 className="commerce"><span>Full Stack</span> Developer</h2>
            <div className="contentinfo">As being an E-Commerce geek, Magento, Shopify, and BigCommece are my expertise. and Ameliorating E-Commerce skills are my favorite passion.</div>
          </div>
        </div>
      </div>
      <div className="aboutpage row col-number-2">
        <div className="col-xl-4 col-lg-4 col-md-12 col-xs-12 order-lg-4 aboutimage aboutimageleft">
          <div className="avatardiv">
            <img className="abotavatar" src="/assets/about_us_page/meet_magento.jpeg" alt="Hitesh Vaghasiya"></img>
          </div>
        </div>
        <div className="col-xl-8 col-lg-8 col-md-12 col-xs-12 order-lg-3 aboutdata aboutdataleft">
          <div className="introw">
            <h2 className="commerce"><span>Magento</span></h2>
            <div className="contentinfo">
              <div>I am one of the Magento lovers. I like to play with Magento and can do anything on this. &#128525;</div>
            </div>
            <h2 className="commerce"><span>Shopify</span></h2>
            <div className="contentinfo">Love to code on the liquid files. As Shopify is hosted solution and has some limitation so like to integrate the challenging code.Shopify is simple but not easy and it is easy but not simple. &#128521;</div>
            <h2 className="commerce"><span>BigCommerce</span></h2>
            <div className="contentinfo">Expert and ameliorating BigCommerce development skill because I believe, Still, there is a scope of learning.&#128578; </div>
          </div>
        </div>
      </div>
      
      <SEO
          title="About"
          url="/about/"
          siteTitleAlt="About Hitesh Vaghasiya"
          isPost={false}
          description="I am Hitesh Vaghasiya, Full Stack Developer. I love the E-Commerce like Magento, Shopify, BigCommerce."
          image="/assets/hiteshvaghasiya.jpg"
        />
    </div>
  </div>
  
);


export default AboutPage;
